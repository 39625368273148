(function ($) {
    'use strict';
    $.plot.uiConstants = {
        SNAPPING_CONSTANT: 20,
        PANHINT_LENGTH_CONSTANT: 10,
        MINOR_TICKS_COUNT_CONSTANT: 4,
        TICK_LENGTH_CONSTANT: 10,
        ZOOM_DISTANCE_MARGIN: 25
    };
})(jQuery);
